import { json, redirect } from 'react-router-dom';
import AuthForm from './AuthForm';

function AuthenticationPage() {
  return <AuthForm />;
}

export default AuthenticationPage;

export async function action({ request }) {
  const mode = new URL(request.url).searchParams.get('mode') || 'login';
  // console.log(request);

  if (mode !== 'login' && mode !== 'signup') {
    throw new json({ message: 'Invalid mode' }, { status: 422 });
  }

  const data = await request.formData();
  // console.log(data);
  // const authData = {
  //   email: data.get('email'),
  //   password: data.get('password'),
  // };
  
  // console.log(authData);
  const response = await fetch('https://api.cppmodel.com/' + mode, {
    method: 'POST',
    encType: 'multipart/form-data',
    mode: 'cors',
    body: data
  });

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: 'Authentication failed' }, { status: 500 });
  }

  // console.log(response);
  const resData = await response.text();
  // console.log(resData);
  const token = resData;

  localStorage.setItem('token', token);
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  localStorage.setItem('expiration', expiration.toISOString());

  return redirect('/');
}