import { Suspense } from "react"
// // import QuoteList from "../components/quotes/QuoteList"
// // import LoadingSpinner from "../components/UI/LoadingSpinner"
// import NoSimulationsFound from "../components/simulations/NoSimulationsFound"
import SimulationList from "../components/simulations/SimulationList"
import { useLoaderData, Await } from 'react-router-dom';

const AllSimulations = (params) => {
    const { simulations } = useLoaderData();
    // const simulations = ["ds"]

    return (
      <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
        <Await resolve={simulations}>
          {(loadedSimulations) => <SimulationList simulations={loadedSimulations} />}
        </Await>
      </Suspense>
    )
}

export default AllSimulations