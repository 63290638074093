import React from 'react';
import Plot from 'react-plotly.js';

const colorList = ["red", "blue", "green", "orange", "grey", "magenta", 'cyan']

const extractData = (name, dataSets, graphData, index) => {
    const { data } = dataSets
    // console.log('Data', data)
    if (data !== null && data !== undefined) {
        data.forEach(set => {
            graphData.push({
                name: `[${name}] ${set.label}`,
                x: set.x,
                y: set.y,
                borderColor: colorList[index],
                line: { shape: 'hv' }
            })
            if (++index >= colorList.length) {
                index = 0;
            }
        })
    }
}


// var pointX = 0;
// var pointY = 0;

const GraphPlotly = (props) => {
    let graphData = []
    let ii = 0
    // console.log('props', props)

    extractData('In', props.inputData, graphData, ii)
    extractData('Out', props.outputData, graphData, ii)

    return (
        <div>
            <div>
                <Plot data={graphData}
                    config={{ scrollZoom: true }}
                    layout={{ dragmode: 'pan', autosize: false, width: window.innerWidth - 100, height: window.innerHeight - 100, title: props.title, xaxis: { title: "time [s]" } }}
                // onClick={clickHandler}
                // onDoubleClick={doubleClickHandler}
                />
            </div>
            {/* <div>
                X <input value={pointX} onChange={pointXChangeHandler} />
                Y <input value={pointY} onChange={pointYChangeHandler} />
            </div> */}
        </div>
    )
}
export default GraphPlotly;
