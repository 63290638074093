import { Form, NavLink, useRouteLoaderData } from 'react-router-dom';

import classes from './MainNavigation.module.css';
// import NewsletterSignup from './NewsletterSignup';

function MainNavigation() {
  const token = useRouteLoaderData('root');

  return (
    <header className={classes.header}>
      <nav>
        <ul className={classes.list}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? `${classes.active} ${classes.navButton}` : classes.navButton
              }
              end
            >
              Simulations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive ? `${classes.active} ${classes.navButton}` : classes.navButton
              }
              end
            >
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/heatmap"
              className={({ isActive }) =>
                isActive ? `${classes.active} ${classes.navButton}` : classes.navButton
              }
              end
            >
              HeatMap
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/newsletter"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Newsletter
            </NavLink>
          </li> */}
          {!token && (
            <li>
              <NavLink
                to="/authentication"
                className={({ isActive }) =>
                  isActive ? `${classes.active} ${classes.navButton}` : classes.navButton
                }
                end
              >
                Authentication
              </NavLink>
            </li>
          )}
          {token && (
            <li>
              <Form action='/logout' method='post'>
                <button>Logout</button>
              </Form>
            </li>
          )}
        </ul>
      </nav>
      {/* <NewsletterSignup /> */}
    </header>
  );
}

export default MainNavigation;
