// src/Heatmap.js
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const generateResults = () => {
  // Generate x vector with 100 consequential steps
  const steps = Array.from({ length: 87 }, (_, i) => i);
  const length = steps.length;

  // Return an array of randomly generated 1s and 0s with the same length as the steps
  const results = Array(length).fill().map(() => Math.round(Math.random()));

  // Return data structured in { x: steps, y: results }
  return { x: steps, y: results };
}

const dataAdapter = (props) => {
  console.log('props', props);
  const { x, y } = props.data[0];
  const newData = { x: x, y: y };
  console.log(newData);
  return newData;
}

const HeatMap = (props) => {
  // const data = generateResults();
  const data = dataAdapter(props.data);
  console.log('generated', generateResults());

  // console.log(props);

  // const descriptions = [
  //   "Step 0: Initialization",
  //   "Step 1: Load Configurations",
  //   "Step 2: Establish Database Connection",
  //   "Step 3: Validate Input Data",
  //   "Step 4: Begin Transaction",
  //   "Step 5: Perform Data Transformation",
  //   "Step 6: Execute Business Logic",
  //   "Step 7: Commit Transaction",
  //   "Step 8: Send Notification",
  //   "Step 9: Log Operation",
  //   "Step 10: Close Database Connection",
  //   "Step 11: Cleanup Temporary Files",
  //   "Step 12: Archive Logs",
  //   "Step 13: Generate Report",
  //   "Step 14: Send Report",
  //   "Step 15: Validate Report Delivery",
  //   "Step 16: Backup Data",
  //   "Step 17: Monitor System Health",
  //   "Step 18: Generate Audit Trail",
  //   "Step 19: Finalize and Shutdown"
  // ];

  const squareSize = 50;
  const [maxStepsPerRow, setMaxStepsPerRow] = useState(10);
  const totalSteps = data.x.length;
  const numRows = Math.ceil(totalSteps / maxStepsPerRow);

  // Dynamically calculate the number of steps per row based on window size
  useEffect(() => {
    const handleResize = () => {
      const newMaxStepsPerRow = Math.floor((window.innerWidth - 100) / squareSize);
      setMaxStepsPerRow(newMaxStepsPerRow);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, [squareSize]);

  let z = [];
  let text = [];
  let xTicks = Array.from({ length: maxStepsPerRow }, (_, i) => i);
  let yTicks = [];

  for (let i = 0; i < numRows; i++) {
    const startIdx = i * maxStepsPerRow;
    const endIdx = Math.min(startIdx + maxStepsPerRow, totalSteps);

    // Get the current row data
    const currentRow = data.y.slice(startIdx, endIdx);
    
    // Add current row to z matrix and text labels
    z.unshift(currentRow.concat(Array(maxStepsPerRow - (endIdx - startIdx)).fill(null)));
    text.unshift(currentRow.map((_, index) => startIdx + index).concat(Array(maxStepsPerRow - (endIdx - startIdx)).fill('')));
    
    // Create yTicks labels in reverse order
    yTicks.unshift(`Row ${i + 1}`);
  }

  const plotData = [{
    z: z,
    x: xTicks,
    y: yTicks,
    type: 'heatmap',
    colorscale: [
      [0, 'rgb(255, 0, 0)'],  // Red for fail (0)
      [1, 'rgb(0, 255, 0)']   // Green for success (1)
    ],
    zmin: 0,  // Explicitly set the minimum value of z
    zmax: 1,  // Explicitly set the maximum value of z
    showscale: false,
    text: text,
    hoverinfo: 'text',
    texttemplate: '%{text}', // Display text inside the heatmap squares
    textfont: {
      family: 'Arial',
      size: 12,
      color: '#000000'
    },
  }];

  const layout = {
    title: props.title,
    xaxis: {
      showgrid: false,
      zeroline: false,
      showticklabels: false,
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
      showticklabels: false,
    },
    margin: { l: 50, r: 50, t: 50, b: 50 },
    height: numRows * squareSize + 100, // Adjust height based on the number of rows
    width: maxStepsPerRow * squareSize + 100, // Adjust width based on steps per row
    autosize: true,
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot data={plotData} layout={layout} useResizeHandler={true} style={{ width: '100%', height: 'calc(100% - 150px)' }} />
      {/* <div style={{ height: '150px', overflowY: 'auto', padding: '10px', borderTop: '1px solid #ccc' }}>
        <h3>Custom Legend</h3>
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {descriptions.map((description, index) => (
            <li key={index} style={{ margin: '5px 0' }}>
              <strong>Step {index}:</strong> {description}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default HeatMap;
