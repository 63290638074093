import styles from './SimulationResults.module.css';

import {
  useLoaderData,
  defer,
  Await,
  Form
} from 'react-router-dom';

import GraphPlotly from '../components/GraphD3';
import { Suspense } from 'react';
import HeatMap from '../components/HeatMap';



function SimulationResults(request, params) {
  const simulationData = useLoaderData('simulation-results');

  console.log(simulationData)

  const getSimulationTime = () => {
    return simulationData.parameter['CppModel.SimulationTime'];
  }

  const getStepSize = () => {
    return simulationData.parameter['CppModel.SimulationStepSize'];
  }

  const getExecutionTime = () => {
    return simulationData.executionTime;
  }

  function filterObjectKeys(obj, keysToRemove) {
    return Object.keys(obj)
      .filter(key => !keysToRemove.includes(key))
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
  }

  function printKeyValuePairsToTable(obj, cellsPerRow) {
    const keys = Object.keys(obj);
    const rows = [];

    for (let i = 0; i < keys.length; i += cellsPerRow) {
      const cells = [];

      for (let j = 0; j < cellsPerRow; j++) {
        const key = keys[i + j];
        if (key !== undefined) {
          cells.push(
            <td key={key}>{key} <input className={styles.parameters} id={key} defaultValue={obj[key]} /></td>
          );
        }
      }

      rows.push(<tr key={i}>{cells}</tr>);
    }

    return (
      <table>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  const getParamaters = () => {
    // console.log(simulationData.parameter);
    // console.log(Object.keys(simulationData.parameter).length);
    if (Object.keys(simulationData.parameter).length > 2) {
      return (
        <div>
          <h3>Parameters</h3>
          {printKeyValuePairsToTable(filterObjectKeys(simulationData.parameter, ['CppModel.SimulationTime', 'CppModel.SimulationStepSize']), 5)}
        </div>
      )
    }
  }

  const printContinousSimulation = () => {
    return (<GraphPlotly inputData={simulationData.input} outputData={simulationData.result} title={simulationData.simulation} />);
  }

  const printUnitTestSteps = () => {
    return (<HeatMap data={simulationData.result} title={simulationData.simulation}/>);
  }

  const printSimulationResults = () => {
    return simulationData.parameter['CppModel.SimulationType'] === 'UnitTestSteps' ? printUnitTestSteps() : printContinousSimulation();
  }

  return (
    <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
      <Await resolve={simulationData}>
        <div>
          <Form method='post'>
            <div className={styles.row}>
              {printSimulationResults()}
            </div>
            {getParamaters()}
            <div className={styles.row}>
              <div className={styles.column}>
                Total time: <input disabled={true} className={styles.simulationTimes} id='CppModel.SimulationTime' defaultValue={getSimulationTime()} />s
                Step size: <input disabled={true} className={styles.simulationTimes} id='CppModel.SimulationStepSize' defaultValue={getStepSize()} />s
                <button disabled={true}>Save</button>
                <button disabled={true}>Save and Run</button>
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  {simulationData.simulation}, executed at {getExecutionTime()}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Await>
    </Suspense>
  );
}

export default SimulationResults;

export async function loader({ request, params }) {
  const id = params.simulationName;

  return defer({ simName: id });
}
export async function setSimulationParametersAction({ request }) {

  console.log(request);
  const data = await request.formData();
  console.log(data);

  return null;
}