import { Fragment } from 'react';


// import SimulationItem from './SimulationItem';
import classes from './SimulationList.module.css';
import SimulationItem from './SimulationItem';

// const sortSimulations = (quotes, ascending) => {
//   return quotes.sort((quoteA, quoteB) => {
//     if (ascending) {
//       return quoteA.id > quoteB.id ? 1 : -1;
//     } else {
//       return quoteA.id < quoteB.id ? 1 : -1;
//     }
//   });
// };

const SimulationList = (props) => {
  // const history = useHistory()
  // const location = useLocation()

  // const queryParams = new URLSearchParams(location.search)

  // const isSortingAscending = queryParams.get('sort') === 'asc'

  // const sortedSimulations = sortSimulations(props.quotes, isSortingAscending)
  const simulations = props.simulations

  // const changeSortingHandler = () => {
  //   history.push({
  //     pathname: location.pathname,
  //     search: `?sort=${((isSortingAscending) ? 'desc' : 'asc')}`
  //   })
  //   // history.push(`${location.pathname}?sort=` + ((isSortingAscending) ? 'desc' : 'asc'))
  // }

  // console.log(simulations)
  return (
    <Fragment>
      {/* <div className={classes.sorting}>
        <button onClick={changeSortingHandler}>Sort {isSortingAscending ? 'Descending' : 'Ascending'}</button>
      </div> */}
      <ul className={classes.list}>
        {simulations.map((simulation) => (
          <SimulationItem
            key={simulation.id}
            name={simulation.name}
            author={simulation.author}
            text={simulation.text}
          />
        ))}
      </ul>
    </Fragment>
  );
};

export default SimulationList;
