// import { Route, Routes } from "react-router-dom";
// // import Layout from "./components/layout/Layout";
// import SimulationResults from "./pages/SimulationResults";
// import AllSimulations from "./pages/AllSimulations";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import { checkAuthLoader } from "./lib/auth";
import AllSimulations from "./pages/AllSimulations";
import SimulationResults, { setSimulationParametersAction } from "./pages/SimulationResults";
import { logoutAction } from "./pages/Logout";
import AuthenticationPage, { action as authAction } from './pages/Authentication';
import { allSimulationsLoader, simulationResultsLoader } from './lib/api'
import HeatMapPage from "./pages/HeatMapPage";

// function App() {
//   return (
//     <Routes>
//       {/* <Layout> */}
//       <Route path="/" element={<AllSimulations />} />
//       {/* <Route path="/simulations" element={<AllSimulations aipKey={apiKey} />}> */}
//       <Route path="/simulations/:simulationName" element={<SimulationResults />} />
//       {/* </Layout> */}
//     </Routes>
//   )
// }

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: 'root',
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        loader: allSimulationsLoader,
        element: <AllSimulations />,
      },
      {
        path: 'simulations/',
        children: [
          {
            index: true,
            loader: allSimulationsLoader,
            element: <AllSimulations />
          },
          {
            path: ':simulationName',
            id: 'simulation-results',
            loader: simulationResultsLoader,
            element: <SimulationResults />,
            action: setSimulationParametersAction
          }
        ]
      },
      {
        path: 'logout',
        action: logoutAction
      },
      { path: 'heatmap', element: <HeatMapPage /> },
    ],
  },
  { path: 'auth', element: <AuthenticationPage />, action: authAction },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;