import { redirect } from "react-router-dom";

export function setToken(newToken, duration) {
    localStorage.setItem('token', newToken);
    localStorage.setItem('expiration', new Date(new Date().getTime() + duration * 1000));
}

export function getAuthToken() {
    const token = localStorage.getItem('token');

    if (!token) {
        return null;
    }

    const tokenDuration = getTokenDuration();

    if (tokenDuration <= 0) {
        return 'EXPIRED';
    }

    return token;
}

export function getTokenDuration() {
    const storedExpiration = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpiration);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}


export function tokenLoader() {
    return getAuthToken();
}

export function checkAuthLoader() {
    if (!isLoggedIn()) {
        return redirect('/auth');
    }
    
    return null;
}

export function isLoggedIn()
{
    const token = getAuthToken();
    if (token != null && token !== undefined && token !== 'EXPIRED') {
        return true;
    }
    return false;
}
