// src/HeatMapPage.js
import React from 'react';
import Heatmap from '../components/HeatMap';

function HeatMapPage() {
  return (
    <div className="HeatMapPage">
      <header className="HeatMapPage-header">
        <h1>Test Heatmap Visualization</h1>
      </header>
      <Heatmap />
    </div>
  );
}

export default HeatMapPage;
