// import axios from 'axios';

import { defer} from 'react-router-dom';
import { getAuthToken } from "./auth";

// const FIREBASE_DOMAIN = 'https://react-dadf0-default-rtdb.europe-west1.firebasedatabase.app';

const connectionHost = 'https://api.cppmodel.com'
// const connectionHost = 'http://localhost'
// const apiPort = 9092

// const apiKey = queryParams.get('apiKey') ?? 'fF_HCouWOSfW0857eIg7s89gTGjUycxUI1OFRYBIgw8'
// const simName = queryParams.get('simName') ?? 'cppsimgraph'

// const getData = useCallback((name) => {

// const response = await fetch(`${connectionHost}/simulations/${simName}/${name}`, {

export async function login() {
  const response = await fetch(`${connectionHost}/login`, {
    method: "POST",
    mode: 'cors'
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch simulations.');
  }

  return data;
}


export async function getAllSimulations() {
  const transformedSimulations = [];
  const token = getAuthToken()
  if (token !== null && token !== 'EXPIRED') {
    const response = await fetch(`${connectionHost}/simulations`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      method: "GET",
      mode: 'cors'
    });
    const data = await response.json();
    // console.log(data)

    if (!response.ok) {
      throw new Error(data.message || 'Could not fetch simulations.');
    }


    //   console.log(data)
    let ii = 0;

    data.forEach(element => {
      const simObj = {
        id: ii++,
        name: element
      };

      transformedSimulations.push(simObj);
    })

  }
  return transformedSimulations;
}

export async function getSimulationInterface(simName, interfaceName) {
  var result = [];
  const token = getAuthToken()
  if (token !== null && token !== 'EXPIRED') {
    // console.log(simName, interfaceName)
    const response = await fetch(`${connectionHost}/simulations/${simName}/${interfaceName}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      method: "GET",
      mode: 'cors'
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || `Could not fetch simulation's ${interfaceName}.`);
    }


    // console.log(data)
    result = data;
  }
  return result;
}

export async function getSimulationData(simName) {
  var result = [];
  const token = getAuthToken()
  if (token !== null && token !== 'EXPIRED') {
    // console.log(simName, interfaceName)
    const response = await fetch(`${connectionHost}/simulations/${simName}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      method: "GET",
      mode: 'cors'
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || `Could not fetch simulation's data.`);
    }

    console.log(data)
    result = data;
  }
  return result;
}

export function allSimulationsLoader() {
  return defer({
    simulations: getAllSimulations()
  });
}

export async function simulationResultsLoader({ params }) {
  let simName = params.simulationName;
  let data = await getSimulationData(simName);
  return defer({
    simulation: simName,
    result: data.results,
    input: data.inputs,
    parameter: data.parameters,
    executionTime: data.executionTime
  });
}

