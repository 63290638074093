import { Link } from 'react-router-dom';
import classes from './SimulationItem.module.css';

const SimulationItem = (props) => {
        // console.log(`/simulations/${props.name}`);
  return (
    <li className={classes.item}>
      <figure>
        <blockquote>
          <p>{props.name}</p>
        </blockquote>
        {/* <figcaption>{props.author}</figcaption> */}
      </figure>
      <Link className='btn' to={`/simulations/${props.name}`}>
        <div>
        View
        </div>
      </Link>
    </li>
  );
};

export default SimulationItem;
